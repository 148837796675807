<template>
    <div>
      <b-card>
        <list-report-head :base-url="exportTrophyCIPServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="2">
          <template v-slot:projectNameSlot>
            {{ }}
          </template>
          {{ currentLocale === 'en' ? 'Summary of National Export Trophy Selection List for Fiscal Year ' + getFiscalYearName(fiscalYearId) : getFiscalYearName(fiscalYearId) + ' অর্থবছরের জন্য জাতীয় রপ্তানি ট্রফি নির্বাচন সংক্রান্ত তালিকার সার সংক্ষেপ' }}
        </list-report-head>
        <b-overlay :show="loading">
          <b-row class="mt-3">
            <b-col sm="12">
              <ul class="list-disc">
                <li>
                  <template v-if="currentLocale === 'en'">
                    The National Export Trophy will be awarded at the state level in recognition of the contribution of individuals, institutions and organizations who have contributed significantly to the growth of Bangladesh's exports. In this context, the list of trophy recipient exporters has been prepared for the fiscal year {{ getFiscalYearName(fiscalYearId) }} based on the National Export Trophy Policy-2022. According to the policy, the decisions taken in the meeting are as follows:
                  </template>
                  <template v-else>
                    বাংলাদেশের রপ্তানি বৃদ্ধিতে যে সকল ব্যক্তি, প্রতিষ্ঠান এবং সংস্থা উল্লেখযোগ্য অবদান রেখেছে তাদের অবদানের স্বীকৃতিস্বরূপ রাষ্ট্রীয় পর্যায়ে পুরুস্কারে ভূষিত করার লক্ষ্যে জাতীয় রপ্তানি ট্রফি প্রদান করা হবে। এ প্রেক্ষিতে জাতীয় রপ্তানি ট্রফি নীতিমালা -২০২২ এর ভিত্তিতে {{ getFiscalYearName(fiscalYearId) }} অর্থবছরের জন্য ট্রফি প্রাপক রপ্তানিকারক প্রতিষ্ঠান এর তালিকা তৈরী করা হয়েছে। উক্ত নীতিমালা অনুযায়ী সভার গৃহীত সিদ্ধান্তসমূহ নিম্নরুপঃ
                  </template>
                </li>
                <li>
                  <template v-if="itemDetails && itemDetails.length">
                    <template v-if="currentLocale === 'en'">
                      A list of organizations should be prepared for selection of National Export Trophy recipients on the basis of minimum export income in {{ $n(itemDetails.length) }} product and service sectors. Sectors with minimum revenue (in millions of US dollars) are as follows:
                    </template>
                    <template v-else>
                      {{ $n(itemDetails.length) }} টি পণ্য ও সেবা খাতে নূন্যতম রপ্তানি আয়ের ভিত্তিতে জাতীয় রপ্তানি ট্রফি প্রাপক নির্বাচনের লক্ষ্যে প্রতিষ্ঠানের তালিকা তৈরী করতে হবে। নূন্যতম  আয়সহ (মিলিয়ন মার্কিন ডলারে) খাতসমূহ নিম্নরূপ:
                    </template>
                  </template>
                </li>
              </ul>
              <b-table-simple class="mt-3"  hover small bordered responsive>
                <b-thead>
                  <b-tr>
                    <b-th class="text-center" width="10%">{{ $t('globalTrans.sl_no') }}</b-th>
                    <b-th class="text-center">{{ $t('exportTrophyConfig.product_and_service_sector') }}</b-th>
                    <b-th class="text-center" width="30%">{{ $t('exportTrophyConfig.minimum_income_usd') }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <template v-if="itemDetails.length">
                    <b-tr v-for="(detailsItem, index) in itemDetails" :key="index">
                      <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                      <b-td class="text-center">{{ getSectorName(detailsItem.product_service_sector_id) }}</b-td>
                      <b-td class="text-center">{{ $n(detailsItem.minimum_income) }}</b-td>
                    </b-tr>
                  </template>
                  <b-tr v-else>
                    <b-td colspan="3" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
        </b-row>
        </b-overlay>
      </b-card>
    </div>
</template>
<script>
import RestApi, { exportTrophyCIPServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './top_sheet_pdf'

export default {
  name: 'TopSheet',
  props: ['fiscalYearId', 'sectorIds'],
  components: {
    ListReportHead
  },
  data () {
    return {
      exportTrophyCIPServiceBaseUrl: exportTrophyCIPServiceBaseUrl,
      item: {
        details: []
      },
      itemDetails: []
    }
  },
  created () {
    if (this.fiscalYearId) {
      this.getIncomeDetails()
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getSectorName (id) {
      const obj = this.$store.state.ExportTrophyCipService.commonObj.exportSectorList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getFiscalYearName (id) {
      const obj = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    async getIncomeDetails () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      // const result = await RestApi.getData(exportTrophyCIPServiceBaseUrl, '/configuration/assign-income/show-income-details/' + this.fiscalYearId)
      const result = await RestApi.getData(exportTrophyCIPServiceBaseUrl, '/configuration/assign-income/show-income-details/' + this.fiscalYearId)
      if (result.success) {
        this.item = result.data
        this.itemDetails = result.data.details.filter(detailsItem => this.sectorIds.includes(detailsItem.product_service_sector_id))
      } else {
        this.item = {
          details: []
        }
        this.itemDetails = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false })
    },
    pdfExport () {
      const reportTitle = this.currentLocale === 'en' ? 'Summary of National Export Trophy Selection List for Fiscal Year ' + this.getFiscalYearName(this.fiscalYearId) : this.getFiscalYearName(this.fiscalYearId) + ' অর্থবছরের জন্য জাতীয় রপ্তানি ট্রফি নির্বাচন সংক্রান্ত তালিকার সার সংক্ষেপ'
      return ExportPdf.exportPdfDetails(exportTrophyCIPServiceBaseUrl, '/configuration/report-heading/detail', 2, reportTitle, this)
    }
  }
}
</script>
<style scoped>
.list-disc {
  list-style-type: disc;
  padding-left: 1rem;
}
</style>
